"use client";
import dynamic from "next/dynamic";

import { observer, useStore } from "../../../service/mobx";
import { ShallowLink } from "../../Link";

const BottomNavigation = dynamic(() =>
  import("@mui/material/BottomNavigation")
);
const BottomNavigationAction = dynamic(() =>
  import("@mui/material/BottomNavigationAction")
);
const IconButton = dynamic(() => import("@mui/material/IconButton"));

function BottomNav() {
  const { analytics, bottomNav, device } = useStore();

  return device.isPhone && bottomNav.loaded ? (
    <BottomNavigation
      id="bottomNav"
      showLabels
      value={bottomNav.index}
      sx={theme => ({
        height: 80,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        position: "fixed",
        zIndex: theme.zIndex.modal,
        boxShadow: theme.elevation[2],
        bgcolor: "surface.container.color"
      })}
    >
      {bottomNav.actions.map(
        ({ label, Icon, href, replace, disabled, onClick }, index) => (
          <BottomNavigationAction
            disableRipple
            key={label}
            label={label}
            disabled={disabled}
            href={bottomNav.index === index ? undefined : href}
            LinkComponent={
              bottomNav.index === index
                ? undefined
                : replace
                ? ShallowLink
                : undefined
            }
            icon={
              <IconButton
                component="div"
                disabled={disabled}
                sx={theme => ({
                  width: 64,
                  height: 32,
                  color: "surface.on.color",
                  borderRadius: theme.shape.round,
                  "&:hover": {
                    bgcolor: "surface.container.highest"
                  }
                })}
                onClick={() => {
                  analytics.track.event("Nav Click", { destination: label });
                  onClick?.();
                }}
              >
                <Icon sx={{ height: 24, width: 24 }} />
              </IconButton>
            }
            sx={{
              "& .MuiBottomNavigationAction-label": {
                typography: "labelMd",
                color: disabled ? "surface.container.dim" : "surface.on.variant"
              },
              "& .MuiBottomNavigationAction-label.Mui-selected": {
                color: "surface.on.color",
                typography: "labelMdProminent"
              },
              "&.Mui-selected .MuiIconButton-root": {
                color: "secondary.on.container",
                bgcolor: "secondary.container"
              }
            }}
          />
        )
      )}
    </BottomNavigation>
  ) : null;
}

export default observer(BottomNav);
