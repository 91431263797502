"use client";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  StarBorderOutlined as StarIconOff,
  Star as StarIconOn
} from "@mui/icons-material";

import { observer, useStore } from "../../../service/mobx";
import Destination from "./Destination";

function MyStuff() {
  const { stars, user } = useStore();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(user.loaded && stars.show && user.isAnonymous === false);
  }, [user.loaded, stars.show, user.isAnonymous]);

  return show ? (
    <Destination
      text="My stuff"
      path="/search"
      match="stars"
      params="?stars=all"
      Off={StarIconOff}
      On={StarIconOn}
    />
  ) : (
    <Box width={56} height={56} />
  );
}

export default observer(MyStuff);
