"use client";
import { Suspense } from "react";
import { useSelectedLayoutSegment } from "next/navigation";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  Search as SearchIcon,
  HomeOutlined as DiscoverIconOff,
  Home as DiscoverIconOn
} from "@mui/icons-material";
import AboutIconOn from "@mui-symbols-material/w400/CircleFilledRounded";
import AboutIconOff from "@mui-symbols-material/w400/CircleOutlined";

import { useStore, observer } from "../../../service/mobx";
import Destination from "./Destination";
import UserAvatar from "../../Avatar/User";
import MyStuff from "./MyStuff";
import { ModelIcon, ModelOnIcon } from "../../Icon/Model";

function NavRail() {
  const { reader } = useStore();
  const isDocs = useSelectedLayoutSegment() === "docs";
  const destinations = [
    {
      text: "Discover",
      path: "/",
      Off: DiscoverIconOff,
      On: DiscoverIconOn
    },
    {
      text: "Models",
      path: "/models",
      Off: ModelIcon,
      On: ModelOnIcon
    },
    {
      text: "Search",
      path: "/search",
      noMatch: "stars",
      Off: SearchIcon,
      On: SearchIcon
    }
  ];

  return (
    <Stack
      id="rail"
      zIndex={1}
      position="sticky"
      alignItems="center"
      justifyContent="space-between"
      ml={{ expanded: -2, large: -3 }}
      top={{ expanded: 16, large: 24 }}
      sx={{
        opacity: isDocs && reader.checkedForWhiteLabel === false ? 0 : 1
      }}
      display={
        reader.whiteLabelled ? "none" : { compact: "none", expanded: "flex" }
      }
      height={{ expanded: "calc(100vh - 32px)", large: "calc(100vh - 48px)" }}
    >
      <IconButton prefetch href="/" sx={{ height: 48, width: 48 }}>
        <Typography
          height={56}
          component="p"
          align="center"
          fontWeight={600}
          fontSize={36}
          variant="brand"
          color="surface.on.color"
        >
          b
        </Typography>
      </IconButton>
      <Stack width={80} px={1.5} spacing={1.5}>
        {destinations.map(({ text, path, noMatch, Off, On }) => (
          <Suspense key={path}>
            <Destination
              text={text}
              path={path}
              On={On}
              Off={Off}
              noMatch={noMatch}
            />
          </Suspense>
        ))}
      </Stack>
      <Stack alignItems="center" spacing={2}>
        <Suspense>
          <Destination
            path="/about"
            text="About"
            Off={AboutIconOff}
            On={AboutIconOn}
          />
        </Suspense>
        <Suspense>
          <MyStuff />
        </Suspense>
        <UserAvatar />
      </Stack>
    </Stack>
  );
}

export default observer(NavRail);
